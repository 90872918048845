@import '~antd/dist/antd.css';

.login {
    width        : 350px;
    height       : auto;
    margin       : auto;
    border       : 1px solid #ccc;
    padding      : 24px;
    margin-top   : 10%;
    border-radius: 10px;

    h1 {
        text-align: center;
    }

    &-form-forgot {
        float: right;
    }

    &-form-button {
        width: 100%;
    }
}

.login- .ant-col-rtl .login-form-forgot {
    float: left;
}

.login-wallet {
    text-align: center;
}