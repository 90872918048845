.filter {
  display: flex !important;
  align-items: center;
  margin-right: 10px;

  &-select {
    width: 100%;
  }
}
.buttonBox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
}
