.modal-box {
    max-width    : 420px;
    width        : 50vw;
    padding      : 20px 24px;
    border-radius: 7.5px;
    background   : #fff;

    &__content {
        display        : flex;
        align-items    : center;
        justify-content: space-between;
        margin-bottom  : 22px;

        h3 {
            font-size  : 15px;
            color      : #1d1e1f;
            font-weight: bold;
            line-height: 0.8;
        }
    }
}

.item-login {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    padding        : 13px 16px;
    margin-bottom  : 10px;
    border-radius  : 7.5px;
    border         : solid 1px #e6e8ef;
    height         : 50px;

    &:hover {
        border: 1px solid green;
        cursor: pointer;
    }
}